<template>
  <b-container fluid class="account-headers accounts-tabs-height">
    <b-row>
      <b-col cols="1" class="text-center">
        <b-img src="/img/account-icons.svg"></b-img>
      </b-col>
      <b-col cols="11">
        <b-row>
          <b-col cols="12">
            <h2>{{$t("profile.infotexts.Notifications-title")}}</h2>
            <p>
              {{$t("profile.infotexts.Notifications")}}
            </p>

            <hr />
          </b-col>
        </b-row>
        <b-row class="account-sub-header pt-5">
          <b-col cols="12">
            <h3 class="mb-3">{{$t("profile.contents.notifications.alerts.title")}}</h3>
          </b-col>

          <b-col cols="3">
            <h6>{{$t("profile.contents.notifications.alerts.text")}}</h6>
          </b-col>
          <b-col cols="9">
            <b-form-checkbox v-model="form.sound" @change="change" name="check-button" switch>
              <img
                v-if="form.sound"
                class="mr-2"
                src="/img/alert-sound.svg"
                alt=""
              />
              <img v-else class="mr-2" src="/img/alert-icon-off.svg" alt="" />
            </b-form-checkbox>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import getNotify from "@/graphql/me/profile/getNotify.graphql";
import updateNotify from "@/graphql/me/profile/updateNotify.graphql";
export default {
  data() {
    return {
      form: {
        sound: false,
        email: {
          follow: false,
          message: false,
          connectter: false,
          connections_product: false,
          connections_inquiry: false
        },
        desktop: {
          follow: false,
          message: false,
          connectter: false,
          connections_product: false,
          connections_inquiry: false
        },
      },
    };
  },
  methods: {
    async getNotify() {
      this.loading = true;
      let data = await this.$apollo.query(getNotify);
      let response = data("**.get", true);
      this.form = response;
      this.loading = false;
    },
    async saveNotify() {
      try {
        this.loading = true;
        await this.$apollo.mutate(updateNotify, { notify: this.form });
        return this.$bvToast.toast(this.$t("Toaster.notifications"), {
          title: this.$t("Toaster.success-title"),
          variant: "success",
          autoHideDelay: 1350,
        });
      } catch (e) {
        
        return this.$bvToast.toast(this.$t(e.message), {
          title: this.$t("Toaster.error-title"),
          variant: "danger",
          autoHideDelay: 1350,
        });
      } finally {
        this.loading = false;
      }
    },
    change(value) {
        this.form.sound = value;
        this.saveNotify();
    }
  },
  created() {
    this.getNotify();
  },
};
</script>
